'use client';
import styled from '@emotion/styled';
import Search from '../Search';
import SearchContent from './SearchContent';
import SearchMob from './SearchMob';
import { useState, useEffect } from 'react';
import SearchRelated from './SearchRelated';

interface Props {
    closeFunc?: () => void;
}

const SearchMobLayer = ({ closeFunc }: Props) => {
    const [layer, setLayer] = useState(0);

    // 컴포넌트가 마운트될 때 body 스크롤 막기
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    const compo = [
        <SearchContent key={0} closeFunc={closeFunc} />,
        // <SearchRelated key={1} closeFunc={closeFunc} />,
    ];

    return (
        <_inner>
            <_searchContainer>
                <SearchMob closeFunc={closeFunc} inputFunc={(num: number) => setLayer(num)} />
                <_contentContainer>{compo[layer]}</_contentContainer>
            </_searchContainer>
        </_inner>
    );
};

export default SearchMobLayer;

const _inner = styled.div`
    width: 100vw;
    background-color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12000;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1026px) {
        display: none;
    }
`;

const _searchContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const _contentContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;
