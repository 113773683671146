import { getApi } from '@/api/axios';
import { CategoryList } from '@/type/categoryType';
import { useQuery } from '@tanstack/react-query';

export const useGetCategory = () => {
  const { data } = useQuery<CategoryList>({
    queryKey: ['category'],
    queryFn: () =>
      getApi({
        url: `product-categories`,
      }),
  });

  const category = data?.data.categoryList || [];

  return { category };
};
