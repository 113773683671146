import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';

// Search
export const SearchInner = styled.div`
    position: relative;
    max-width: 100%;
    width: 480px;
    display: flex;
    align-items: center;
    flex-direction: row;

    > img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 26px;
        z-index: 3;
        cursor: pointer;

        &.left {
            display: block;
            right: auto;
            left: 6px;
            z-index: 4;
        }
    }

    ${mediaDown('tablet')} {
        margin: 0 20px;
        max-width: 380px;

        > img {
            right: 0;

            &.left {
                display: none;
            }
        }
    }

    /* ${mediaUp('mobile')} {
        width: 480px;
    } */
`;
export const SearchInput = styled.input`
    border: none;
    width: 100%;
    min-height: 48px;
    font-size: 16px;
    padding-left: 46px;
    padding-right: 40px;
    border-bottom: 1px solid #000000;
    position: relative;
    z-index: 3;

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #c8c8c8;
    }

    ${mediaUp('mobile')} {
        padding-left: 5px;
    }
`;

export const SearchButton = styled.span`
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: ${theme.zIndex.floating + 1};
    display: flex;

    ${mediaUp('mobile')} {
        right: 0;
    }
`;

export const SearchResultsWrapper = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: ${theme.zIndex.floating};
    background: #fff;
    /* border-top: 1px solid #eee; */

    ${mediaUp('mobile')} {
        top: calc(100% + 10px);
        /* border: 1px solid #eee; */
        border-radius: 4px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: ${theme.zIndex.floating};
    ${mediaUp('mobile')} {
        background-color: transparent;
    }
`;

export const BackButton = styled.span`
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: ${theme.zIndex.floating + 1};
    display: block;

    ${mediaUp('mobile')} {
        display: none;
    }
`;
