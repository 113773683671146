'use client';

import styled from '@emotion/styled';
import search from '@/public/search.svg';
import LeftArrow from '@/public/svgs/left_arrow.svg';
import Image from 'next/image';
import { useRef, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { saveSearch } from '@/util/searchFunc';

interface Props {
    closeFunc?: () => void;
    inputFunc: (num: number) => void;
}

const SearchMob = ({ closeFunc, inputFunc }: Props) => {
    const router = useRouter();
    const inputRef = useRef<HTMLInputElement>(null);

    const params = useSearchParams();
    const keyword = params?.get('keyword');

    const keywordLength = () => {
        return inputRef.current?.value.trim().length || 0;
    };

    return (
        <_inner>
            <Image className="left" src={LeftArrow} alt="arrow" onClick={closeFunc} />
            <_input
                ref={inputRef}
                defaultValue={keyword || ''}
                type="text"
                placeholder="검색어를 입력해주세요."
                //onFocus={() => setOn(true)}
                onKeyDown={(e) => {
                    if (!keywordLength()) return;
                    if (e.key === 'Enter') {
                        saveSearch(e.currentTarget.value);
                        router.push(`/search?keyword=${e.currentTarget.value}`);
                        closeFunc?.();
                    }
                }}
                onFocus={(e) => {
                    const text = e.currentTarget.value.trim();
                    if (text.length) {
                        inputFunc(1);
                    } else {
                        inputFunc(0);
                    }
                }}
                onInput={(e) => {
                    const text = e.currentTarget.value.trim();
                    if (text.length) {
                        inputFunc(1);
                    } else {
                        inputFunc(0);
                    }
                }}
            />
            <Image
                src={search}
                alt="search"
                onClick={() => {
                    if (!keywordLength()) return;
                    saveSearch(inputRef.current?.value.trim() || '');
                    router.push(`/search?keyword=${inputRef.current?.value.trim()}`);
                    closeFunc?.();
                }}
            />
        </_inner>
    );
};

const _inner = styled.div`
    position: relative;
    width: 480px;

    @media screen and (max-width: 1026px) {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    > img {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: 3;
        &.left {
            display: none;
        }
        @media screen and (max-width: 1026px) {
            right: 26px;
            &.left {
                display: block;
                right: auto;
                left: 6px;
                z-index: 4;
            }
        }
    }
`;
const _input = styled.input`
    border: none;
    width: 100%;
    min-height: 48px;
    font-size: 16px;
    padding-left: 5px;
    border-bottom: 1px solid #000000;
    position: relative;
    z-index: 3;
    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #c8c8c8;
    }
    @media screen and (max-width: 1026px) {
        padding-left: 46px;
    }
`;

const _wrap = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 3;
    @media screen and (max-width: 1026px) {
        top: 100%;
    }
`;

const _otherWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 2;
`;

export default SearchMob;
