'use client';
import Search from './Search';
import Image from 'next/image';
import Link from 'next/link';
import Navi from './Navi';
import { useRouter, usePathname } from 'next/navigation';
import LeftArrow from '@/public/svgs/left_arrow.svg';
// import SearchIcon from "@/public/svgs/search.svg";
import ExitIC from '@/public/svgs/exit_icon.svg';
import HomeIC from '@/public/svgs/home_icon.svg';
import { Suspense, useContext, useEffect, useState } from 'react';
import SearchMobLayer from './search/SearchMobLayer';
import { getCookie, hasCookie, removeCookie } from '@/api/cookie';
import PCHeaderBtns from './PCHeaderIBtns';
import { useGetCategory } from '../_hook/useGetCategory';
import axios from 'axios';
import { ModalContextDispatch } from './modal/modalProvider/ModalContext';
import ModalTwoBtns from './modal/ModalTwoBtns';
import BrandHeader from './BrandHeader';
import { useHeaderScroll } from '@/app/_hook/useHeaderScroll';
import { useHeaderHeight } from '@/app/_hook/useHeaderHeight';
// Icon Hooks
import useIcon from '@/app/_hook/useIcon';
import { LOGOUT_URL } from '@/api/axios';

// Styles
import { _gap, _header, _pcWrap, _inner, _flex, _flex2, _mbInner, _mainWrap, _brandWrap, _LogoContainer, _IconButton, _IconContainer, _IconList, NavContainer, NavContent, MoNavList, MoNavItem } from '@/app/styles/components/header/mainheader';
import { instance } from '@/api/axios';

// const testList = [
//   '브랜드',
//   '패션의류',
//   '패션잡화',
//   '뷰티',
//   '푸드',
//   '홈/리빙',
//   '디지털/가전',
//   '문구/취미/여가',
//   '스몰럭셔리',
//   '럭셔리',
// ];

type Props = {
    title?: string;
};

export const HEADER_HEIGHTS = {
    MOBILE: {
        MAIN: '98px',
        SUB: '0px',
    },
    PC: {
        DEFAULT: '137px',
        SUB: '137px',
    },
} as const;

export const MAIN_PATHS = ['/', '/prlist', '/promotion', '/ranking'] as const;

const Header = ({ title }: Props) => {
    const { IcLogo, IcSearch, IcLogin, IcBell, IcLogout, IcCart } = useIcon();
    const { isScrollingDown, topStyle, bottomStyle } = useHeaderScroll();
    const { isMainPath } = useHeaderHeight();
    const router = useRouter();
    const pathName = usePathname();
    const { category } = useGetCategory();

    const { addModal, clearModal } = useContext(ModalContextDispatch);

    const [searchModal, setSearchModal] = useState(false);

    const [user, setUser] = useState('');

    // console.log('🚀 pathName==>', pathName);

    useEffect(() => {
        const isUser = hasCookie('accessToken') ? 'on' : 'off';
        setUser(isUser);
    }, []);

    const getTitle = (): string => {
        switch (pathName) {
            case '/':
                return '홈';
            case '/basket':
                return '장바구니';
            case '/order':
                return '주문서';
            case '/orderFinish':
                return '주문완료';
            case '/review':
                return '리뷰 모아보기';
            case '/review/detail':
                return '리뷰 상세';
            case '/promotion':
                return '기획전';
            case '/promotion/detail':
                return '기획전';
            case '/ranking':
                return '랭킹';
            default:
                return '';
        }
    };

    const handleLeftFn = () => {
        switch (pathName) {
            case '/login':
                return router.push('/');
            // case "/signUp":
            //   return router.push("/login");

            default:
                return router.back();
        }
    };

    const checkPath = () => {
        return pathName === '/' || pathName === '/prlist' || pathName === '/promotion' || pathName === '/ranking' ? '137px' : '48px';
    };

    const handleRightFn = () => {
        router.push('/');
    };

    const logOut = () => {
        let isLoggingOut = false;
        addModal(
            <ModalTwoBtns
                content="로그아웃 하시겠습니까?"
                onClickBtn={async () => {
                    if (isLoggingOut) {
                        return;
                    }

                    isLoggingOut = true;

                    try {
                        // ReactNative WebView 처리
                        if ((global as any).ReactNativeWebView) {
                            (global as any).ReactNativeWebView.postMessage(
                                JSON.stringify({
                                    data: {
                                        type: 'logout',
                                    },
                                })
                            );
                        }

                        // 쿠키 제거

                        removeCookie('accessToken');
                        removeCookie('refreshToken');

                        // 헤더에서 인증 정보 제거
                        delete instance.defaults.headers.common['Authorization'];

                        // 로그아웃 API 호출
                        await instance.post(LOGOUT_URL).catch(() => {});

                        clearModal();
                        setUser('');
                        router.push('/login');
                    } catch (error) {
                        console.error('Logout failed:', error);
                    } finally {
                        isLoggingOut = false;
                    }
                }}
                onClickCancel={clearModal}
                BtnText="네"
                cancelText="아니오"
            />
        );
    };

    return (
        <Suspense>
            {searchModal && <SearchMobLayer closeFunc={() => setSearchModal(false)} />}
            <_gap $isMainPath={isMainPath} />
            <_header>
                {/* MOBILE (홈 제외)*/}
                <_mbInner>
                    {pathName === '/brand' ? (
                        <></>
                    ) : pathName === '/' || pathName === '/prlist' || pathName === '/promotion' || pathName === '/ranking' ? (
                        <_mainWrap>
                            <_flex className="main" style={topStyle}>
                                <_LogoContainer onClick={() => router.push('/')}>
                                    <span className="visually-hidden">홈으로 이동</span>
                                    <IcLogo size={64} color="#000" aria-hidden="true" />
                                </_LogoContainer>
                                {user && (
                                    <_IconContainer>
                                        <_IconList role="list">
                                            <li>
                                                <_IconButton onClick={() => setSearchModal(true)}>
                                                    <span className="visually-hidden">검색창 열기</span>
                                                    <IcSearch size={32} color="#000" aria-hidden="true" />
                                                </_IconButton>
                                            </li>
                                            <li>
                                                <_IconButton
                                                    onClick={() => {
                                                        user === 'on' ? logOut() : router.push('/login');
                                                    }}>
                                                    <span className="visually-hidden">{user === 'on' ? '로그아웃' : '로그인'}</span>
                                                    {user === 'on' ? <IcLogout size={32} color="#000" aria-hidden="true" /> : <IcLogin size={32} color="#000" aria-hidden="true" />}
                                                </_IconButton>
                                            </li>
                                            <li>
                                                <Link href={user === 'on' ? '/my/alert' : '/login'}>
                                                    <span className="visually-hidden">알림 확인하기</span>
                                                    <_IconButton>
                                                        <IcBell size={32} color="#000" aria-hidden="true" />
                                                    </_IconButton>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link href="/basket">
                                                    <span className="visually-hidden">장바구니</span>
                                                    <_IconButton>
                                                        <IcCart size={32} color="#000" aria-hidden="true" />
                                                    </_IconButton>
                                                </Link>
                                            </li>
                                        </_IconList>
                                    </_IconContainer>
                                )}
                            </_flex>
                            <_flex2 style={bottomStyle}>
                                <NavContainer>
                                    <NavContent>
                                        <MoNavList>
                                            <MoNavItem>
                                                <Link href="/">
                                                    <div className={pathName === '/' ? 'on' : ''}>
                                                        <p>추천</p>
                                                    </div>
                                                </Link>
                                            </MoNavItem>
                                            <MoNavItem>
                                                <Link href="/prlist">
                                                    <div className={pathName === '/prlist' ? 'on' : ''}>
                                                        <p>타임 특가</p>
                                                    </div>
                                                </Link>
                                            </MoNavItem>
                                            <MoNavItem>
                                                <Link href="/promotion">
                                                    <div className={pathName === '/promotion' ? 'on' : ''}>
                                                        <p>기획전</p>
                                                    </div>
                                                </Link>
                                            </MoNavItem>
                                            <MoNavItem>
                                                <Link href="/ranking">
                                                    <div className={pathName === '/ranking' ? 'on' : ''}>
                                                        <p>랭킹</p>
                                                    </div>
                                                </Link>
                                            </MoNavItem>
                                        </MoNavList>
                                    </NavContent>
                                </NavContainer>
                            </_flex2>
                        </_mainWrap>
                    ) : (
                        <>
                            <span className="left" onClick={handleLeftFn}>
                                {pathName === '/orderFinish' ? <Image src={ExitIC} alt="exit" /> : <Image src={LeftArrow} alt="arrow" />}
                            </span>
                            <span className="title">{getTitle()}</span>
                            <span className="right" onClick={handleRightFn}>
                                {pathName === '/orderFinish' ? <Image src={HomeIC} alt="home" /> : ''}
                            </span>
                        </>
                    )}
                </_mbInner>
                {/* PC */}
                <_pcWrap $isScrolled={isScrollingDown}>
                    <_inner>
                        <_flex>
                            <_LogoContainer onClick={() => router.push('/')}>
                                <IcLogo size={78} color="#000" />
                            </_LogoContainer>
                            <Search />
                            <PCHeaderBtns />
                        </_flex>
                        <Navi list={category} />
                    </_inner>
                </_pcWrap>
            </_header>
        </Suspense>
    );
};

export default Header;
