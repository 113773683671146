// hooks/useHeaderScroll.ts
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

const SCROLL_THRESHOLD = 5;
const MOBILE_HEADER_HEIGHT = 48;
const TRANSITION_DURATION = '0.2s';

export const useHeaderScroll = () => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const lastScrollY = useRef(0);
    const scrollTimeout = useRef<NodeJS.Timeout>();

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;

        // 작은 스크롤 무시
        if (Math.abs(currentScrollY - lastScrollY.current) < SCROLL_THRESHOLD) {
            return;
        }

        // 스크롤 방향 체크 및 상태 업데이트
        if (currentScrollY > MOBILE_HEADER_HEIGHT) {
            setIsScrollingDown(currentScrollY > lastScrollY.current);
        }

        // 마지막 스크롤 위치 업데이트
        lastScrollY.current = currentScrollY;

        // 스크롤 멈춤 감지
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }

        scrollTimeout.current = setTimeout(() => {
            const finalScrollY = window.scrollY;
            if (finalScrollY <= MOBILE_HEADER_HEIGHT) {
                setIsScrollingDown(false);
            }
        }, 150);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }
        };
    }, [handleScroll]);

    const styles = useMemo(
        () => ({
            isScrollingDown,
            topStyle: {
                transform: isScrollingDown ? `translateY(-${MOBILE_HEADER_HEIGHT}px)` : 'translateY(0)',
                transition: `transform ${TRANSITION_DURATION} ease-in-out`,
                position: 'fixed' as const,
                top: 0,
                left: 0,
                right: 0,
                background: 'white',
            },
            bottomStyle: {
                top: isScrollingDown ? '0' : `${MOBILE_HEADER_HEIGHT}px`,
                transition: `top ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
            },
        }),
        [isScrollingDown]
    );

    return styles;
};
