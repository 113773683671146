import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { zIndex } from '@/app/styles/base/zIndex';
import { HEADER_HEIGHTS } from '@/app/_common/Header';

const HEADER_HEIGHT = '48px';

export const _gap = styled.div<{ $isMainPath: boolean }>`
    ${mediaDown('mobile')} {
        padding-top: ${({ $isMainPath }) => ($isMainPath ? HEADER_HEIGHTS.MOBILE.MAIN : HEADER_HEIGHTS.MOBILE.SUB)};
    }

    ${mediaUp('mobile')} {
        padding-top: ${({ $isMainPath }) => ($isMainPath ? HEADER_HEIGHTS.PC.DEFAULT : HEADER_HEIGHTS.PC.SUB)};
    }
`;

export const _header = styled.header`
    /* position: relative; */
    /* border: 1px solid red; */

    z-index: 200;
    width: 100%;
    background-color: white;

    ${mediaUp('mobile')} {
        position: sticky;
        top: 0;
        left: 0;
    }
`;

export const _LogoContainer = styled.h1`
    cursor: pointer;
    display: flex;
`;

/** 
PC 헤더
*/
export const _pcWrap = styled.div<{ $isScrolled: boolean }>`
    width: 100%;

    ${mediaUp('mobile')} {
        display: block;
        width: 100%;
        border-bottom: 1px solid ${theme.colors.textDarker[50]};
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: ${zIndex.sticky};
        background: #fff;
        height: 137px;
        /* transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1); */
        /* height: ${({ $isScrolled }) => ($isScrolled ? `80px` : `137px `)}; */

        .modal-open & {
            width: calc(100% - var(--scrollbar-width, 0px));
        }
    }
`;

export const _inner = styled.div`
    max-width: ${breakpoints.largeDesktop};
    width: 100%;
    margin: 0 auto;

    ${mediaDown('mobile')} {
        display: none;
    }
`;

export const _flex = styled.div`
    height: 80px;
    padding: 0 0 20px 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    line-height: 0;

    ${mediaDown('desktop')} {
        /* padding: 0px 40px; */
    }

    ${mediaDown('tablet')} {
        /* padding: 0px 20px; */
    }

    ${mediaDown('mobile')} {
        padding: 0px 0px 0 16px;
        height: auto;
        min-height: 48px;
        z-index: ${zIndex.sticky + 2};
        /* transition: all 1.5s cubic-bezier(0.4, 0, 0.2, 1); */

        /* > h1 {
            padding: 7px;
            > img {
                width: 71px;
                height: 34px;
            }
        } */
    }

    .main {
        @media screen and (min-width: 1026px) {
            display: none;
        }
    }
`;

/** 
모바일 헤더
*/

export const _flex2 = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    height: ${HEADER_HEIGHT};
    z-index: ${zIndex.sticky + 1};
    background: ${theme.colors.white};

    &::before {
        content: '';
        position: absolute;
        top: -${HEADER_HEIGHT};
        left: 0;
        width: 100%;
        height: ${HEADER_HEIGHT};
        background: ${theme.colors.white};
        z-index: ${zIndex.hide};
    }
    /* justify-content: left;
    align-items: center;
    border-bottom: 1px solid;
    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
        padding: 12px 16px;
        color: #a0a0a0;
        position: relative;
        &.on {
            color: black;
            &::after {
                display: block;
                content: '';
                width: 100%;
                height: 2px;
                background-color: black;
                position: absolute;
                bottom: -1px;
                left: 0;
            }
        }
    } */
`;

export const _mbInner = styled.div`
    position: relative;
    display: none;
    .left {
        padding: 10px;
        min-width: 44px;
    }
    .right {
        padding: 10px;
        min-width: 44px;
    }
    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
    }
    ${mediaDown('mobile')} {
        ${theme.display.flexRowBetween};
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 2;
    }
`;

export const _mainWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const _brandWrap = styled(_mainWrap)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const _IconButton = styled.span`
    display: flex;
    padding: 5px;
    margin: 3px 1px;
    color: ${theme.colors.textDarker[200]};
    cursor: pointer;

    > a {
        display: flex;
    }
`;

export const _IconContainer = styled.div`
    /* margin-right: -29px; */

    height: ${HEADER_HEIGHT};
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mediaDown('mobile')} {
        padding: 0 0 0 6px;
    }
`;

export const _IconList = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

/** 
모바일 NAV
*/

export const NavContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.white};
    overflow: initial;
    border-bottom: 1px solid ${theme.colors.black};
`;

export const NavContent = styled.div`
    flex: 1 0 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`;

export const MoNavList = styled.nav`
    display: flex;
    width: 100%;
    align-items: stretch;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 32px 0 16px;
    margin: 0 -2px;
    scrollbar-width: none;

    ${mediaDown('mobile')} {
        scrollbar-width: auto;
    }
`;

export const MoNavItem = styled.span`
    margin: 0px 2px;

    > a {
        display: inline-block;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: top;
        text-align: center;
        box-sizing: border-box;
        padding: 15px 5px;
        height: inherit;
        outline: none;

        > div {
            padding: 4px;
            margin: -4px;
            color: ${theme.colors.textDarker[100]};

            &.on {
                &::after {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: ${theme.colors.black};
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                > p {
                    color: ${theme.colors.black};
                    font-weight: ${theme.fontWeight.medium};
                }
            }
            > p {
                font-size: ${theme.fontSize.base};
                line-height: ${theme.lineHeight.tight};
                font-weight: ${theme.fontWeight.medium};
                position: relative;
            }
        }
    }

    ::after {
        content: '';
        position: absolute;
        right: 0px;
        width: 59px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%);
    }
`;

// Nav
export const NavigationWrapper = styled.div<{ $isScrolled: boolean }>`
    /* max-width: ${breakpoints.largeDesktop};
    margin: 0 auto;
    display: block;
    width: 100%;
    padding: 0px 2px;
    height: ${({ $isScrolled }) => ($isScrolled ? '0' : `56px`)};
    opacity: ${({ $isScrolled }) => ($isScrolled ? '0' : '1')};
    visibility: ${({ $isScrolled }) => ($isScrolled ? 'hidden' : 'visible')};
    transform: ${({ $isScrolled }) => ($isScrolled ? 'translateY(-10px)' : 'translateY(0)')};
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1); */
    max-width: ${breakpoints.largeDesktop};
    margin: 0 auto;
    display: block;
    width: 100%;
    padding: 0;
    height: 56px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);

    ${mediaDown('desktop')} {
        padding: 0px 5px;
        /* padding: 0 30px; */
    }

    ${mediaDown('tablet')} {
        padding: 0 10px;
    }

    ${mediaDown('mobile')} {
        display: none;
    }
`;

export const Navigation = styled.nav`
    width: 100%;
    height: 100%;
    justify-content: space-between;
    display: flex;
    align-items: stretch;
    white-space: nowrap;

    /* -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: auto; */

    ${mediaDown('tablet')} {
        /* margin: 0px -10px; */
        scrollbar-width: auto;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
    }
`;

export const NavSection = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const NavList = styled.ul`
    display: flex;
    font-weight: ${theme.fontWeight.medium};
    height: 100%;
`;

export const NavItem = styled.li`
    position: relative;
    /* padding: 0 5px; */
    padding: 0;
    height: inherit;
    cursor: pointer;

    > a {
        margin: 0px;
        border: none;
        background: none;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: top;
        text-align: center;
        box-sizing: border-box;
        /* padding: 12px 8px; */
        padding: 12px 20px;
        height: 100%;
        outline: none;
        text-decoration: none;

        &:hover {
            color: ${theme.colors.black};
        }

        ${mediaDown('tablet')} {
            padding: 12px 10px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${theme.colors.black};
        opacity: 0;
        z-index: ${zIndex.sticky};
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        &::after {
            opacity: 1;
        }

        > ul {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    > ul {
        display: none;
        position: absolute;
        top: calc(100% + 0px);
        left: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
        will-change: transform, opacity;
        z-index: ${theme.zIndex.dropdown};
    }
`;
