"use client";
import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";
import Link from "next/link";

interface Sub {
  [key: string]: number | string | Sub[] | undefined; // 임의의 문자열 키에 대해 문자열 또는 Sub 배열을 허용
}

interface Props {
  depth: number;
  item: Sub[];
  type?: "drop";
}

const CategoryMenu = ({ item, type, depth }: Props) => {
  const query = useQueryClient();

  const cache = () => {
    query.invalidateQueries({ queryKey: ["cateProductList"] });
  };

  return (
    <_ul type={type}>
      {item.map((ele, idx) => {
        const categoryIdx = `category${depth}Idx`;
        const category = `category${depth}`;
        const categoryList = ele[`category${depth + 1}List`] as Sub[];

        return (
          <li key={idx}>
            <Link
              href={`/cateprlist?category${depth}Idx=${ele[categoryIdx]}`}
              onClick={cache}
            >
              {`${ele[category]}`}
            </Link>
            {categoryList?.length ? (
              <CategoryMenu item={categoryList} depth={depth + 1} />
            ) : (
              <></>
            )}
          </li>
        );
      })}
    </_ul>
  );
};

export default CategoryMenu;

const _ul = styled.ul<{ type?: string }>`
  position: absolute;
  top: ${({ type }) => (type ? "calc(100%)" : "0px;")};
  left: ${({ type }) => (type ? "0" : "100%;")};
  width: max-content;
  min-height: 44px;
  background-color: white;
  display: none;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    border-top: 1px solid #e0e0e0;
    width: 100%;
  }

  > li {
    cursor: pointer;
    background-color: white;
    width: 168px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 16px;
    color: #a0a0a0;
    > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: #f4f4f4;
      color: black;
      > ul {
        display: block;
      }
    }
  }
  /* &.on {
    display: block;
  } */
`;
