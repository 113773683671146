import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { zIndex } from '@/app/styles/base/zIndex';

interface _UpButtonProps {
    $show: boolean;
    $hasBottomNav: boolean;
}

interface _UpProdButtonProps {
    $show: boolean;
    $hasProductNav: boolean;
}

interface _UpMyButtonProps {
    $show: boolean;
}
export const _Footer = styled.footer<{ $isOrderPage?: boolean }>`
    position: relative;
    width: 100%;
    background-color: ${theme.colors.bgDarker[50]};
    padding: 52px 0;

    @media screen and (max-width: 1025px) {
        color: ${theme.colors.darkGray800};
        padding: 24px 0 80px;

        ${({ $isOrderPage }) =>
            $isOrderPage &&
            `
                display: none;
            `}
    }
`;
export const _Inner = styled.div`
    max-width: 1570px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    color: ${theme.colors.darkGray800};

    @media screen and (max-width: 1580px) {
    }

    @media screen and (max-width: 1280px) {
    }

    @media screen and (max-width: 1025px) {
        flex-direction: column;
        color: black;
    }
`;

export const _CompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    /* max-width: 800px; */

    @media screen and (max-width: 1280px) {
        max-width: 100%;
    }

    @media screen and (max-width: 1025px) {
        max-width: 100%;
    }
`;

export const _RightSection = styled.div`
    display: flex;
    justify-content: flex-end;

    min-width: 400px;
    align-items: flex-end;

    @media screen and (max-width: 1570px) {
        justify-content: center;
    }

    @media screen and (max-width: 1280px) {
        min-width: 280px;
    }

    @media screen and (max-width: 1025px) {
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        border-bottom: 1px solid ${theme.colors.textDarker[50]};
        padding-bottom: 24px;
        margin: 0 0 16px 0;
        order: -1;
        min-width: auto;
    }
`;

export const _Disclaimer = styled.p`
    font-size: 12px;
    color: ${theme.colors.textDarker[200]};
    line-height: 1.5;
    margin-top: 16px;

    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }
`;

export const _CustomerService = styled.div`
    display: flex;
    flex-direction: column;
`;

export const _Title = styled.h2`
    font-size: 14px;
    font-weight: 500;
`;

export const _PhoneNumber = styled.p`
    font-size: 24px;
    font-weight: 700;
    margin: 4px 0;
    font-family: ${theme.fontFamily.en};
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    /* iOS에서 전화번호 자동 감지 방지 */
    pointer-events: none;
    /* Safari에서 텍스트 크기 자동조절 방지 */
    -webkit-text-size-adjust: none;
    /* 터치 시 기본 하이라이트 제거 */
    -webkit-touch-callout: none;
    user-select: none;
`;

export const _ServiceInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 4px;
`;

export const _InfoItem = styled.p`
    font-size: 14px;
    color: ${theme.colors.textDarker[200]};
`;

export const _Terms = styled.div`
    display: flex;
    gap: 16px;
    padding-bottom: 20px;
    /* border-bottom: 1px solid ${theme.colors.gray7}; */

    > a > button.css-112zws0 {
        padding-left: 0;
        ::before {
            display: none;
        }
    }
`;

export const _TermButton = styled.button`
    color: ${theme.colors.textDarker[200]};
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;
    height: 40px;

    :nth-of-type(1),
    :nth-of-type(2) {
        padding-left: 16px;

        ::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 16px;
            background-color: ${theme.colors.textDarker[50]};
        }
    }

    @media screen and (max-width: 1025px) {
        color: ${theme.colors.textDarker[200]};
        font-size: 14px;
    }
`;

export const _InfoLine = styled.p`
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    font-size: 12px;
    line-height: 18px;
    color: ${theme.colors.textDarker[200]};
    :last-of-type {
        margin-bottom: 5px;
    }

    > span {
        position: relative;
        padding-right: 10px;
        padding-left: 10px;

        :first-of-type {
            padding-right: 10px;
            padding-left: 0px;
            ::after {
                display: none;
            }
        }
        ::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 10px;
            background-color: ${theme.colors.textDarker[50]};
        }
    }

    @media screen and (max-width: 1024px) {
        font-size: 12px;
        gap: 4px;
        line-height: 1.25;

        :first-of-type {
            margin-bottom: 10px;
        }
    }
`;

export const _BusinessLink = styled.a`
    color: inherit;
    text-decoration: underline;
    -webkit-text-decoration: underline;
    /* 터치 시 기본 스타일 방지 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
        color: ${theme.colors.textDarker[100]};
    }

    /* iOS에서 일관된 스타일 유지 */
    @media (hover: none) {
        &:active {
            color: ${theme.colors.textDarker[100]};
        }
    }

    @media screen and (max-width: 1025px) {
        &:hover {
            color: #000;
        }
        text-decoration: underline;
        -webkit-text-decoration: underline;
    }
`;

export const _Copyright = styled.p`
    font-size: 12px;
    color: ${theme.colors.black};
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }
`;

export const _UpButton = styled.button<_UpButtonProps>`
    position: fixed;
    right: 31px;
    bottom: ${(props) => (props.$show ? '31px' : '-46px')};
    width: 56px;
    height: 56px;
    background-color: white;
    border: 1px solid ${theme.colors.textDarker[100]};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${theme.zIndex.sticky + 1};
    opacity: ${(props) => (props.$show ? 1 : 0)};
    pointer-events: ${(props) => (props.$show ? 'auto' : 'none')};
    transition: opacity 0.5s, bottom 0.5s;

    ${mediaDown('mobile')} {
        width: 48px;
        height: 48px;
        right: 12px;
        /* bottom: ${(props) => (props.$show ? '84px' : '-46px')}; */
        bottom: ${(props) => (props.$show ? (props.$hasBottomNav ? '86px' : '16px') : '-46px')};
        z-index: ${theme.zIndex.sticky - 1};

        > svg {
            width: 16px;
            height: 16px;
        }
    }
`;

export const _UpProdButton = styled.button<_UpProdButtonProps>`
    position: fixed;
    right: 31px;
    bottom: ${(props) => (props.$show ? '31px' : '-46px')};
    width: 56px;
    height: 56px;
    background-color: white;
    border: 1px solid ${theme.colors.textDarker[100]};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${theme.zIndex.sticky + 1};
    opacity: ${(props) => (props.$show ? 1 : 0)};
    pointer-events: ${(props) => (props.$show ? 'auto' : 'none')};
    transition: opacity 0.5s, bottom 0.5s;

    ${mediaDown('mobile')} {
        width: 48px;
        height: 48px;
        right: 12px;
        bottom: ${(props) => (props.$show ? (props.$hasProductNav ? '86px' : '16px') : '-46px')};
        z-index: ${theme.zIndex.base + 1};

        > svg {
            width: 16px;
            height: 16px;
        }
    }
`;

export const _UpMyButton = styled.button<_UpMyButtonProps>`
    position: fixed;
    right: 31px;
    bottom: ${(props) => (props.$show ? '31px' : '-46px')};
    width: 56px;
    height: 56px;
    background-color: white;
    border: 1px solid ${theme.colors.textDarker[100]};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${theme.zIndex.sticky + 1};
    opacity: ${(props) => (props.$show ? 1 : 0)};
    pointer-events: ${(props) => (props.$show ? 'auto' : 'none')};
    transition: opacity 0.5s, bottom 0.5s;

    ${mediaDown('mobile')} {
        width: 48px;
        height: 48px;
        right: 12px;
        bottom: ${(props) => (props.$show ? '86px' : '-46px')};
        z-index: ${theme.zIndex.base + 1};

        > svg {
            width: 16px;
            height: 16px;
        }
    }
`;
