'use client';

import styled from '@emotion/styled';
import search from '@/public/search.svg';
import LeftArrow from '@/public/svgs/left_arrow.svg';
import Image from 'next/image';
import { useRef, useState } from 'react';
import SearchContent from './search/SearchContent';
import SearchRelated from './search/SearchRelated';
import { useRouter, useSearchParams } from 'next/navigation';
import { saveSearch } from '@/util/searchFunc';
import { SearchInner, SearchInput, SearchResultsWrapper, Overlay, SearchButton, BackButton } from '@/app/styles/components/header/search';
import useIcon from '../_hook/useIcon';

interface Props {
    closeFunc?: () => void;
}

const Search = ({ closeFunc }: Props) => {
    const { IcSearch, IcBack } = useIcon();
    const router = useRouter();
    const [on, setOn] = useState(false);
    const [inputed, setInputed] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const params = useSearchParams();
    const keyword = params?.get('keyword');

    const keywordLength = () => {
        return inputRef.current?.value.trim().length || 0;
    };

    return (
        <SearchInner>
            <BackButton onClick={closeFunc}>
                <IcBack size={30} color="#000" />
            </BackButton>
            <SearchInput
                ref={inputRef}
                type="text"
                defaultValue={keyword || ''}
                placeholder="검색어를 입력해주세요."
                //onFocus={() => setOn(true)}
                onKeyDown={(e) => {
                    if (!keywordLength()) return;
                    if (e.key === 'Enter') {
                        saveSearch(e.currentTarget.value.trim());
                        location.href = `/search?keyword=${e.currentTarget.value.trim()}`;
                    }
                }}
                onFocus={(e) => {
                    const text = e.currentTarget.value.trim();
                    if (text.length) {
                        setOn(false);
                        setInputed(true);
                    } else {
                        setOn(true);
                        setInputed(false);
                    }
                }}
                onInput={(e) => {
                    const text = e.currentTarget.value.trim();
                    if (text.length) {
                        setOn(false);
                        setInputed(true);
                    } else {
                        setOn(true);
                        setInputed(false);
                    }
                }}
            />
            <SearchButton
                onClick={() => {
                    if (!keywordLength()) return;
                    saveSearch(inputRef.current?.value.trim() || '');
                    location.href = `/search?keyword=${inputRef.current?.value.trim()}`;
                }}>
                <IcSearch size={30} color="#000" />
            </SearchButton>

            {on && (
                <>
                    <Overlay onClick={() => setOn(false)} />
                    <SearchResultsWrapper className="223">
                        <SearchContent closeFunc={() => setOn(false)} />
                    </SearchResultsWrapper>
                </>
            )}
            {/* {inputed && (
        <>
          <_otherWrap onClick={() => setInputed(false)} />
          <_wrap>
            <SearchRelated closeFunc={() => setInputed(false)} />
          </_wrap>
        </>
      )} */}
        </SearchInner>
    );
};

export default Search;
