'use client';
import styled from '@emotion/styled';
import Image from 'next/image';
import login from '@/public/logout.svg';
import my from '@/public/my.svg';
import bag from '@/public/bag.svg';
import Link from 'next/link';
import BellIcon from '@/public/svgs/bell.svg';
import { useContext, useEffect, useState } from 'react';
import { hasCookie, removeCookie } from '@/api/cookie';
import { ModalContextDispatch } from './modal/modalProvider/ModalContext';
import ModalTwoBtns from './modal/ModalTwoBtns';
import { useRouter } from 'next/navigation';
import ModalOneBtn from './modal/ModalOneBtn';
import logout from '@/public/ic-logout.svg';
import useIcon from '../_hook/useIcon';
import { instance, LOGOUT_URL } from '@/api/axios';
// Styles
import { _IconContainer, _IconList, _IconButton } from '@/app/styles/components/header/mainheader';
type Props = {};

const PCHeaderBtns = ({}: Props) => {
    const { IcLogo, IcSearch, IcLogin, IcBell, IcLogout, IcCart, IcMy } = useIcon();
    const router = useRouter();
    const [user, setUser] = useState('');
    const [mounted, setMounted] = useState(false);

    const { addModal, clearModal } = useContext(ModalContextDispatch);

    useEffect(() => {
        const isUser = hasCookie('accessToken') ? 'on' : 'off';
        console.log('isUser->', isUser);

        setUser(isUser);
        setMounted(true);
    }, []);

    const logOut = () => {
        let isLoggingOut = false;
        addModal(
          <ModalTwoBtns
            content="로그아웃 하시겠습니까?"
            onClickBtn={async () => {
                if (isLoggingOut) {
                    return;
                }
  
                isLoggingOut = true;
  
                try {
                    // ReactNative WebView 처리
                    if ((global as any).ReactNativeWebView) {
                        (global as any).ReactNativeWebView.postMessage(
                            JSON.stringify({
                                data: {
                                    type: "logout",
                                },
                            })
                        );
                    }
  
                    // 쿠키 제거
                    removeCookie('accessToken');
                    removeCookie('refreshToken');
                    
                    // 헤더에서 인증 정보 제거
                    delete instance.defaults.headers.common['Authorization'];
                    
                    // 로그아웃 API 호출
                    await instance.post(LOGOUT_URL).catch(() => {});
                    
                    clearModal();
                    setUser('');
                    router.push('/login');
                } catch (error) {
                    console.error('Logout failed:', error);
                } finally {
                    isLoggingOut = false;
                }
            }}
            onClickCancel={clearModal}
            BtnText="네"
            cancelText="아니오"
          />
        );
      };

    return (
        <nav aria-label="사용자 메뉴">
            <_wrap>
                <_IconContainer>
                    <_IconList role="list">
                        <li>
                            <_IconButton
                                onClick={() => {
                                    user === 'on' ? logOut() : router.push('/login');
                                }}>
                                <span className="visually-hidden">{user === 'on' ? '로그아웃' : '로그인'}</span>
                                {user === 'on' ? <IcLogout size={38} color="#000" aria-hidden="true" /> : <IcLogin size={38} color="#000" aria-hidden="true" />}
                            </_IconButton>
                        </li>
                        <li>
                            <Link href={user === 'on' ? '/my/alert' : '/login'}>
                                <span className="visually-hidden">알림 확인하기</span>
                                <_IconButton>
                                    <IcBell size={38} color="#000" aria-hidden="true" />
                                </_IconButton>
                            </Link>
                        </li>
                        <li>
                            <Link href={user === 'on' ? '/my' : '/login'}>
                                <span className="visually-hidden">마이페이지</span>
                                <_IconButton>
                                    <IcMy size={38} color="#000" aria-hidden="true" />
                                </_IconButton>
                            </Link>
                        </li>
                        <li>
                            <Link href="/basket">
                                <span className="visually-hidden">장바구니</span>
                                <_IconButton>
                                    <IcCart size={38} color="#000" aria-hidden="true" />
                                </_IconButton>
                            </Link>
                        </li>
                    </_IconList>
                </_IconContainer>
            </_wrap>
        </nav>
    );
};

export default PCHeaderBtns;

const _wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        cursor: pointer;
    }

    @media screen and (max-width: 1026px) {
        gap: 12px;
    }
`;
