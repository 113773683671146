'use client';
import styled from '@emotion/styled';
import CategoryMenu from './category/CategoryMenu';
import Link from 'next/link';
import { SubCate1 } from '@/type/categoryType';
import { useQueryClient } from '@tanstack/react-query';
import { useHeaderScroll } from '@/app/_hook/useHeaderScroll';

import { NavigationWrapper, Navigation, NavSection, NavList, NavItem } from '@/app/styles/components/header/mainheader';
interface Props {
    list: SubCate1[];
}
//기존 ecf처럼 새로고침 방식으로 이동할지?
const Navi = ({ list }: Props) => {
    const { isScrollingDown } = useHeaderScroll();
    const query = useQueryClient();

    const cache = (queryKey: string) => {
        query.invalidateQueries({ queryKey: [queryKey] });
    };
    return (
        <NavigationWrapper $isScrolled={isScrollingDown}>
            <Navigation>
                <NavSection>
                    <NavList>
                        {list.map((ele, idx) => (
                            <NavItem key={idx}>
                                <Link href={`/cateprlist?category1Idx=${ele.category1Idx}`} onClick={() => cache('cateProductList')}>
                                    {ele.category1}
                                </Link>
                                {ele.category2List.length ? <CategoryMenu item={ele.category2List} type="drop" depth={2} /> : null}
                            </NavItem>
                        ))}
                    </NavList>
                </NavSection>
                <NavSection>
                    <NavList>
                        <NavItem>
                            <Link href={`/prlist?type=sale&page=1`}>타임세일</Link>
                        </NavItem>
                        <NavItem>
                            <Link onClick={() => cache('promotionList')} href={`/promotion`}>
                                기획전
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link href={`/ranking`}>랭킹</Link>
                        </NavItem>
                    </NavList>
                </NavSection>
            </Navigation>
        </NavigationWrapper>
    );
};

export default Navi;
